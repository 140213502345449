<template>
  <MainHeader/>
  <router-view/>
</template>

<script>

import {defineComponent} from "vue";
import MainHeader from "@/components/MainHeader.vue";

export default defineComponent({
  components: {MainHeader}
})
</script>

<style>
#app {
  font-family: "Roboto Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

body {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #000000;
  color: white;
}

nav {
  padding: 30px;
}

a {
  color: white;
}


nav a {
  font-weight: bold;
  color: #555555;
}

nav a.router-link-exact-active {
  color: white;
}
</style>
