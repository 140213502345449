<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const video = ref(null);
const countdown = ref(3);

onMounted(() => {
  const setVideoSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const videoRatio = video.value.videoWidth / video.value.videoHeight;
    const windowRatio = windowWidth / windowHeight;

    if (windowRatio > videoRatio) {
      video.value.style.width = `${windowWidth}px`;
      video.value.style.height = `${(windowWidth / videoRatio) - 200}px`;
    } else {
      video.value.style.width = `${windowHeight * videoRatio}px`;
      video.value.style.height = `${windowHeight - 200}px`;
    }
  };

  setVideoSize();
  window.addEventListener('resize', setVideoSize);

  const route = useRoute();
  const router = useRouter();
  if (route.path === '/easter') {
    const timer = setInterval(() => {
      countdown.value--;
      if (countdown.value === 0) {
        clearInterval(timer);
        router.push('/');
      }
    }, 1000);
  }
});
</script>

<template>
  <div class="video-container">
    <video ref="video" autoplay loop muted>
      <source src="@/static/pvz.mp4" type="video/mp4">
    </video>
    <h2>easter egg doesn't exist, go away! redirecting in {{ countdown }} seconds...</h2> <br>
  </div>
</template>


<style scoped>
.video-container {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}
h2 {
  padding: 30px;
}

.video-container video {
  object-fit: cover;
}
</style>
