<template>
  <div class="about">
    <h1>shit blog</h1>
    <button @click="toggleOrder" class="toggle-button">
      {{ orderAsc ? 'Newer' : 'Older' }}
    </button>
    <div v-if="posts.length" class="posts-container">
      <div v-for="post in orderedPosts" :key="post.id" class="post">
        <h2 class="title">{{ post.title }}</h2>
        <p class="text">{{ post.text }}</p>
        <p class="author">{{ post.author }}</p>
      </div>
    </div>
    <div v-else>
      <p>Loading posts...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      posts: [],
      orderAsc: true
    }
  },
  async created() {
    try {
      const response = await axios.get('https://joraly.ru/api/posts');
      this.posts = response.data;
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    orderedPosts() {
      return this.orderAsc ? this.posts : [...this.posts].reverse();
    }
  },
  methods: {
    toggleOrder() {
      this.orderAsc = !this.orderAsc;
    }
  }
}
</script>

<style scoped>
.posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post {
  width: 600px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.title {
  margin-bottom: 10px;
}

.text {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.author {
  font-size: 0.8em;
  text-align: right;
}

h1 {
  color: white;
}

.toggle-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  margin-bottom: 20px;
  font-family: "Roboto Mono", monospace;
}

.toggle-button:hover {
  background-color: red;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
  .post {
    width: 100%;
    padding: 15px;
  }

  .toggle-button {
    width: 100%;
    padding: 15px;
    font-size: large;
  }

  h1 {
    font-size: 1.5em;
  }

  .title {
    font-size: 1.2em;
  }

  .text {
    font-size: 1em;
  }

  .author {
    font-size: 0.7em;
  }
}

@media (max-width: 480px) {
  .post {
    padding: 10px;
  }

  .toggle-button {
    padding: 10px;
    font-size: medium;
  }

  h1 {
    font-size: 1.2em;
  }

  .title {
    font-size: 1em;
  }

  .text {
    font-size: 1.2em;
  }

  .author {
    font-size: 0.8em;
  }
}
</style>
