<template>
  <div class="about">
    <h1 class="title">my projects</h1>
    <div v-if="projects.length" class="card-container">
      <div v-for="project in projects" :key="project.id" class="card">
        <h5 class="card-title">{{ project.name }}</h5>
        <p class="card-text">{{ project.description }}</p>
        <a :href="project.link" class="btn" :class="getRepoIconClass(project.link)">
          <font-awesome-icon :icon="getRepoIcon(project.link)" />
        </a>
      </div>
    </div>
    <div v-else>
      <p>Loading projects...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      projects: [],
      faGithub,
      faGitlab,
      faLink
    }
  },
  async created() {
    try {
      const response = await axios.get('https://joraly.ru/api/projects');
      this.projects = response.data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    getRepoIconClass(link) {
      if (link.includes('github.com')) {
        return 'btn-github';
      } else if (link.includes('gitlab.com')) {
        return 'btn-gitlab';
      } else {
        return 'btn-link';
      }
    },
    getRepoIcon(link) {
      if (link.includes('github.com')) {
        return this.faGithub;
      } else if (link.includes('gitlab.com')) {
        return this.faGitlab;
      } else {
        return this.faLink;
      }
    }
  }
}
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.title {
  color: white;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.card {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card-title {
  margin-bottom: 10px;
}

.card-text {
  margin-bottom: 20px;
}

.btn-github {
  color: #fff;
  font-size: 25px;
}

.btn-gitlab {
  color: #E24329;
  font-size: 25px;
}

.btn-link {
  color: #fff;
  font-size: 25px;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 400px;
    padding: 15px;
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-text {
    font-size: 1em;
  }

  .btn-github, .btn-gitlab, .btn-link {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
    max-width: 300px;
    padding: 10px;
  }

  .card-title {
    font-size: 1em;
  }

  .card-text {
    font-size: 0.9em;
  }

  .btn-github, .btn-gitlab, .btn-link {
    font-size: 18px;
  }

  .title {
    font-size: 1.5em;
  }
}

@media (max-width: 405px) {
  .card {
    width: 100%;
    max-width: 250px;
    padding: 10px;
  }

  .card-title {
    font-size: 0.9em;
  }

  .card-text {
    font-size: 0.8em;
  }

  .btn-github, .btn-gitlab, .btn-link {
    font-size: 16px;
  }

  .title {
    font-size: 1.2em;
  }
}
</style>
