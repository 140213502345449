<template>
  <div class="card-container">
    <h1>Admin Panel</h1>
    <div class="card">
      <h2 class="card-title">Add Project</h2>
      <form @submit.prevent="addProject">
        <input type="text" v-model="newProject.name" placeholder="Name" required class="input-field">
        <input type="text" v-model="newProject.description" placeholder="Description" class="input-field">
        <input type="text" v-model="newProject.link" placeholder="Link" required class="input-field">
        <button type="submit">Add Project</button>
      </form>
    </div>
    <div class="card">
      <h2 class="card-title">Add Post</h2>
      <form @submit.prevent="addPost">
        <input type="text" v-model="newPost.title" placeholder="Title" required class="input-field">
        <textarea v-model="newPost.content" placeholder="Content" required class="textarea-field"></textarea>
        <button type="submit">Add Post</button>
      </form>
    </div>
  </div>
</template>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
}

.card {
  width: 50%;
  margin: 20px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 80px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #000000;
  color: #FFFFFF;
}

.card-title {
  margin-bottom: 10px;
}

.card-text {
  margin-bottom: 20px;
}

.input-field {
  width: 80%;
  padding: 10px;
  margin: 10px auto;
  border: 2px solid #ccc;
  background: black;
  color: white;
  border-radius: 20px;
  display: block;
  font-family: "Roboto Mono", monospace;
}

.textarea-field {
  width: 80%;
  padding: 10px;
  margin: 10px auto;
  border: 2px solid #ccc;
  background: black;
  color: white;
  border-radius: 20px;
  display: block;
  resize: vertical; /* Allow vertical resizing */
  min-height: 50px; /* Set a minimum height */
}

button {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 60px;
  border-radius: 40px;
  font-size: 15px;
  margin-top: 10px;
  font-family: "Roboto Mono", monospace;
}

button:hover {
  background-color: #FF4444;
}

h1 {
  color: white;
}
@media (max-width: 405px) {
  card {
    border: 0px solid #ccc;
  }
  button {
    padding: 0;
    background-color: black;
  }
}
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newProject: {
        name: '',
        description: '',
        link: ''
      },
      newPost: {
        title: '',
        content: ''
      }
    };
  },
  methods: {
    async addProject() {
      try {
        await axios.post('https://joraly.ru/api/projects', this.newProject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.newProject = {
          name: '',
          description: '',
          link: ''
        };
        alert('Project added successfully');
      } catch (error) {
        console.error(error);
        alert('Failed to add project');
      }
    },
    async addPost() {
      try {
        await axios.post('https://joraly.ru/api/posts', this.newPost, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.newPost = {
          title: '',
          content: ''
        };
        alert('Post added successfully');
      } catch (error) {
        console.error(error);
        alert('Failed to add post');
      }
    }
  }
};
</script>
