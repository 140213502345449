<template>
  <div id="app">
    <div id="joraly" class="joraly">
      <h1>joraly</h1>
    </div>
    <div class="default_info">
      <p>
        Java software engineer from Moscow.<br>
        18 y.o.<br>
        <b>My stack:</b><br>
        <font-awesome-icon :icon="['fab', 'java']" /> Java (Core, Concurrency), Kotlin, Spring (Data, Security, Cloud), JDBC, Hibernate, Maven, Gradle, OpenSearch, Liquibase, Docker, Kafka, RabbitMQ, Redis<br>
        <b>Extra:</b><br>
        JUnit, Mockito, Git, Linux, Bash, Jsoup, Vue.js, React.js
      </p>
    </div>
    <div class="links">
      <font-awesome-icon :icon="['fab', 'github']" /><a href="https://github.com/ThisJoraly" class="link">github</a>
      <font-awesome-icon :icon="['fab', 'telegram']" /><a href="https://t.me/joraly" class="link">telegram</a>
    </div>
    <div class="main">
      <router-link to="/easter" class="egg"></router-link>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faJava, faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faJava, faGithub, faTelegram)

export default {
  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  data() {
    return {
      showEgg: Math.random() < 0.95
    }
  }
}
</script>
<style>
.link {
  margin-right: 10px;
  text-decoration: underline;
}
.joraly {
  color: white;
  transition: color 0.1s;
  display: inline-block;
  width: auto;
  padding: 5px;
  background-color: black;
  text-align: center;
}

.joraly:hover {
  color: red;
}

h1 {
  font-size: xxx-large;
}
.egg {
  position: static;
  bottom: auto;
  right: auto;
  width: 20px;
  height: 25px;
  aspect-ratio: 3 / 4;
  border-radius: 100% / 125% 125% 80% 80%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.egg:hover {
  transform: scale(1.1);
}
</style>