<template>
  <div class="card-container">
    <div class="card">
      <form @submit.prevent="login">
        <input type="text" v-model="username" placeholder="Username" class="input-field" required />
        <input type="password" v-model="password" placeholder="Password" class="input-field" required />
        <button type="submit" class="btn">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://joraly.ru/api/login', {
          username: this.username,
          password: this.password
        });
        // Store the token in local storage
        localStorage.setItem('token', response.data.token);
        // Redirect to the admin dashboard
        this.$router.push('/admin');
      } catch (error) {
        console.error(error);
        // Handle login error
      }
    }
  }
}
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
}

.card {
  width: 50%;
  margin: 20px;
  padding: 20px;
  border-radius: 40px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #000000;
  color: #FFFFFF;
}

.card-title {
  margin-bottom: 20px;
  font-size: 24px;
}

.input-field {
  width: 80%;
  padding: 10px;
  margin: 10px auto;
  border: 2px solid #ccc;
  border-radius: 5px;
  display: block;
  background-color: #000000;
  color: #FFFFFF;
  font-family: "Roboto Mono", monospace;
}

.btn {
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  width: 80%;
  font-family: "Roboto Mono", monospace;
  font-size: larger;
}

.btn:hover {
  background-color: #DD0000;
}
</style>
