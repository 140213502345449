<template>
  <header class="header">

    <h1 :class="getLogoColor()">joraly
      <router-link to="/easter" class="egg" :class="{ 'hover': showEgg }" v-if="showEgg && !isEasterPage"></router-link>
    </h1>

    <nav>
      <ul class="nav">
        <li><router-link to="/">home</router-link></li>
        <li><router-link to="/projects">projects</router-link></li>
        <li><router-link to="/blog">blog</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showEgg: Math.random() < 0.02
    }
  },
  computed: {
    isEasterPage() {
      return this.$route.path === '/easter';
    }
  },
  methods: {
    getLogoColor() {
      const randomNumber = Math.floor(Math.random() * 10) + 1;
      if (randomNumber === 1) {
        return 'logo-red';
      } else {
        return 'logo-white';
      }
    }
  }
}
</script>

<style scoped>
html {
  background: black;
}
body {
  font-family: "Roboto Mono", monospace;
  color: white;
}

a {
  color: white;
  margin: 1px;
}

.header {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header h1 {
  display: flex;
  align-items: center;
}

nav a.router-link-exact-active {
  color: red;
}

h1 {
  font-family: "Roboto Mono", monospace;
  font-size: 30px;
  margin: 0;
  transition: color 0.5s ease;
}

.logo-white {
  color: white;
}

.logo-red {
  color: red;
  animation: color-change 0.5s ease;
}

@keyframes color-change {
  0% {
    color: white;
  }
  100% {
    color: red;
  }
}

.nav {
  font-family: "Roboto Mono", monospace;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav li {
  margin-left: 20px;
}

.egg {
  margin-left: 10px;
  width: 20px;
  height: 25px;
  aspect-ratio: 3 / 4;
  border-radius: 100% / 125% 125% 80% 80%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.egg:hover {
  transform: scale(1.1);
}
.egg.hover:hover {
  background-color: red;
}

</style>
